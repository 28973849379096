import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import TitleSection from "../TitleSection/TitleSection";
import SectionalTabs from "../SectionalTabs/SectionalTabs";
import PlayerBenefits from "./PlayerBenefits";
import TypesOfPoints from "./TypesOfPoints";
import HowToRedeem from "./HowToRedeem";
import FAQ from "./FAQ";
import RulesConditions from "./RulesConditions";

const RewardsDetails = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Define the routeCategory once
  const routeCategory = "rewards";

  // Fetch categories from the localized JSON dynamically
  const categories = require(
    `../../locales/${i18n.language}/pseudoRoutes.json`
  );
  const categoryArr = Object.values(
    categories.pseudoRoutes[routeCategory] || {}
  );
  // Initialize the state to the first category title and path
  const [selectedCategory, setSelectedCategory] = useState(categoryArr[0]?.title);

  useEffect(() => {
    const currentTab = categoryArr?.find((el) => el.path === location.pathname);
    if (currentTab && currentTab.title !== selectedCategory) {
      setSelectedCategory(currentTab?.title);
    }else {
      setSelectedCategory(categoryArr[0]?.title);
    }
  }, [location.pathname]);
  useEffect(() => {
    // Only navigate to the base `/rewards` path if the current path isn't already a valid tab path
    const currentTab = categoryArr.find((el) => el.path === location.pathname);

    // If there's no valid tab in the current URL, navigate to `/rewards`
    if (!currentTab && location.pathname !== t("routes.rewards")) {
      navigate(t("routes.error"));
    }
  }, [i18n.language, location.pathname]);
  return (
    <container-div>
      {/* Static Introductory Text (Above Tabs) */}
      <TitleSection
        as={"h2"}
        title={t("rewards.section-2-title")} // Static heading
        text={t("rewards.section-2-text")} // Static description
      />

      {/* Dynamic Content Based on Selected Tab */}
      <SecondPartMain id="rewards-tab">
        {/* Dynamic Tabs */}
        <SectionalTabs
          routeData={{
            route: t(`routes.rewards`),
            routeCategory,
            sectionTitle: categoryArr[0]?.title || "",
          }}
          isDynamic={true}
          selectedCategory={selectedCategory} // Pass the selected category for active tab control
          bgColor="var(--mutedred)"
        />

        {/* Dynamic Title Based on Selected Tab (Below Tabs, Above Content) */}
        <DynamicTitle>
          <TitleSection
            as={"h1"}
            dark={true}
            title={selectedCategory}
            text={
              categoryArr.find((cat) => cat.title === selectedCategory)
                ?.description || ""
            }
          />
        </DynamicTitle>

        {selectedCategory ===
          t("pseudoRoutes.rewards.playerBenefits.title") && <PlayerBenefits />}
        {selectedCategory === t("pseudoRoutes.rewards.typesOfPoints.title") && (
          <TypesOfPoints />
        )}
        {selectedCategory === t("pseudoRoutes.rewards.howToRedeem.title") && (
          <HowToRedeem />
        )}
        {selectedCategory === t("pseudoRoutes.rewards.faq.title") && <FAQ />}
        {selectedCategory ===
          t("pseudoRoutes.rewards.rulesConditions.title") && (
          <RulesConditions />
        )}
      </SecondPartMain>
    </container-div>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SecondPartMain = styled.main`
  width: 100%;
  height: fit-content;
  max-width: 1440px;
  & > div:last-child {
    animation: ${fadeIn} 0.3s linear;
  }
  .sectional-tabs {
    margin-bottom: 1rem;
  }
`;

const DynamicTitle = styled.div`
  background: var(--white);
  .title-section {
    padding-bottom: 0;
  }
`;

export default RewardsDetails;
