import { toTitleCase } from "./formatters";

export const tournamentTypeClasses = {
  1: "daily",
  2: "satellite",
  4: "online",
  5: "festival",
};

export const tournamentTypeToString = (tournamentType) => {
  return tournamentTypeClasses[tournamentType];
};

export const getLateRegLevel = (late_registration_level) => {
  if (!late_registration_level) {
    console.log("No event details provided");
    return null;
  }
  if (
    Array.isArray(late_registration_level) &&
    late_registration_level.length > 0
  ) {
    // Get last element of the array
    return late_registration_level.at(-1)?.Level;
  } else {
    return late_registration_level;
  }
};

export const maxPlayersString = (maxPlayers, alternates, t, short = false) => {
  if (!maxPlayers || maxPlayers == 0 || maxPlayers == "0") {
    return null;
  }
  const maxPlayersText = t("tournament.card.maxPlayers", {
    maxPlayers: maxPlayers,
  });

  const alternatesText = alternates ? t("tournament.card.alternates") : "";
  const maxPlayersString = short
    ? maxPlayers + alternatesText
    : maxPlayersText + alternatesText;
  return maxPlayersString;
};

// Checks if an item is in the account holder's cart
export const isItemInCart = (accountCart, eventDetailsId) => {
  if (!accountCart || !eventDetailsId) {
    console.log("No account cart or event details provided");
    return false;
  }
  let isItemInCart = accountCart.order_items?.some(
    (item) => String(item?.item_details.id) === String(eventDetailsId)
  );
  return isItemInCart;
};

// Used to generate the JSON-LD data for a single tournament event
export const generateJSONLD = (event, language, t) => {
  const eventTitle = event[`name_${language}`] || event.name_en;
  const alternateTitle = language === "en" ? event.name_fr : event.name_en; // Set alternate title

  const jsonLdEvent = {
    "@context": "http://schema.org",
    "@type": "Event",
    name: eventTitle,
    alternateName: alternateTitle || "", // Include alternate language title
    startDate: event.start,
    description:
      `${toTitleCase(tournamentTypeClasses[event.type])} ${event.event_name ? event.event_name : ""}${event.event_number ? ` - #${event.event_number}` : ""}` ||
      "",
    eventType: t("tournament.pokerTournament"),
    url: event.url || window.location.href,
    location: {
      "@type": "Place",
      name: "Playground",
      address: {
        "@type": "PostalAddress",
        streetAddress: "1500 Unit C Route 138",
        addressLocality: "Kahnawake",
        addressRegion: "QC",
        postalCode: "J0L 1B0",
        addressCountry: "CA",
      },
    },
    organizer: {
      "@type": "Organization",
      name: "Playground",
      url: "https://www.playground.ca",
    },
  };

  return jsonLdEvent;
};

// Returns an array of all years from 2011 to this year
export const getYearsArray = () => {
  return Array.from({ length: new Date().getFullYear() - 2011 + 1 }, (_, i) =>
    (new Date().getFullYear() - i).toString()
  );
};

// Returns all data for past events, with each year as a key. Joins all the yeary data into a single object.
export const getAllPastEventsData = async (i18n) => {
  const yearsArray = getYearsArray();
  // Initialize an object to store all past events data with the year as the key
  let allYearsData = {};
  // Loop through each year and import the corresponding JSON file
  for (const year of yearsArray) {
    try {
      const pastEventsLocale = await import(
        `src/locales/${i18n.language}/pastEvents/pastEvents${year}.json`
      );
      const pastEventsData = pastEventsLocale[`pastEvents${year}`];
      allYearsData[year] = pastEventsData;
    } catch (error) {
      console.error(`Error loading past events for year ${year}:`, error);
    }
  }
  // console.log("All Past Events Data:", allYearsData);
  return allYearsData;
};

export const getSinglePastEventData = async (location, i18n) => {
  let eventData = null;

  try {
    // Determine the pathname from location
    const { pathname } = location;
    // Loop through each year to find the event
    const yearsArray = getYearsArray();
    for (const year of yearsArray) {
      try {
        const pastEventsLocale = await import(
          `src/locales/${i18n.language}/pastEvents/pastEvents${year}.json`
        );
        const pastEventsData = pastEventsLocale[`pastEvents${year}`];

        // Find the event by pathname
        for (const key in pastEventsData) {
          const event = pastEventsData[key];
          if (event.url === pathname) {
            eventData = event;
            break;
          }
        }

        if (eventData) break;
      } catch (error) {
        console.error(`Error loading past events for year ${year}:`, error);
      }
    }

    if (!eventData) {
      throw new Error(`No matching event found for pathname: ${pathname}`);
    }
  } catch (error) {
    console.error(
      `Error loading event data for pathname ${location.pathname}: ${error.message}`
    );
  }

  // If no event data is found, try to load the default language (English) as a fallback
  if (!eventData && i18n.language !== "en") {
    try {
      for (const year of getYearsArray()) {
        try {
          const pastEventsLocale = await import(
            `src/locales/en/pastEvents/pastEvents${year}.json`
          );
          const pastEventsData = pastEventsLocale[`pastEvents${year}`];

          // Find the event by pathname
          for (const key in pastEventsData) {
            const event = pastEventsData[key];
            if (event.url === pathname) {
              eventData = event;
              break;
            }
          }

          if (eventData) break;
        } catch (error) {
          console.error(`Error loading past events for year ${year}:`, error);
        }
      }

      if (!eventData) {
        throw new Error(`No matching event found for pathname: ${pathname}`);
      }
    } catch (error) {
      console.error(
        `Error loading event data for pathname ${location.pathname}: ${error.message}`
      );
    }
  }
  return eventData;
};

import tournamentsEn from "src/locales/en/tournaments.json";
import tournamentsFr from "src/locales/fr/tournaments.json";

export const getTournamentData = async (location, i18n) => {
  let tournamentData = null;
  let tabsData = null;

  try {
    // Determine the pathname from location
    const { pathname } = location;

    // Select the correct tournaments.json file based on language
    const tournamentsConfig =
      i18n.language === "fr"
        ? tournamentsFr.tournaments
        : tournamentsEn.tournaments;

    // Loop through tournaments to find a matching URL in the routes
    let matchedTournamentKey = null;
    let matchedTabKey = null;

    for (const tournamentKey in tournamentsConfig) {
      const tabs = tournamentsConfig[tournamentKey];
      for (const tabKey in tabs) {
        if (tabs[tabKey] === pathname) {
          matchedTournamentKey = tournamentKey;
          matchedTabKey = tabKey;
          break;
        }
      }
      if (matchedTournamentKey && matchedTabKey) break;
    }

    if (matchedTournamentKey && matchedTabKey) {
      // Load the corresponding JSON file based on the matched tournament and tab
      const camelCaseTournamentKey = matchedTournamentKey;
      const jsonFileName = matchedTabKey ? matchedTabKey : "schedule";
      const tournamentDataLocale = await import(
        /* webpackChunkName: "tournament-data" */ `src/locales/${i18n.language}/tournaments/${camelCaseTournamentKey}/${jsonFileName}.json`
      );

      tournamentData = tournamentDataLocale[jsonFileName] || null;

      // Attempt to load tabs.json if it exists
      try {
        const tabsDataLocale = await import(
          /* webpackChunkName: "tabs-data" */ `src/locales/${i18n.language}/tournaments/${camelCaseTournamentKey}/tabs.json`
        );
        tabsData = tabsDataLocale.tabs || null; // Access the 'tabs' property directly
      } catch (tabsError) {
        // console.error(
        //   `Tabs data not found for tournament: ${matchedTournamentKey}`,
        //   tabsError
        // );
      }
    } else {
      throw new Error(
        `No matching tournament or tab found for pathname: ${pathname}`
      );
    }
  } catch (error) {
    console.error(
      `Error loading tournament data for pathname ${location.pathname}: ${error.message}`
    );
    throw new Error(
      `No matching tournament or tab found for pathname: ${pathname}`
    );
  }

  return { tournamentData, tabsData };
};

