import React from "react";
import styled from "styled-components";
import Modal  from 'react-bootstrap/Modal';
import DetailsCard from "../Poker/Tournaments/TournamentDetailsCard";
import { FaTimes } from "react-icons/fa";
import { tournamentTypeToString } from '../../utils/tournamentUtils';

const CalendarModal = ({ showDetails, handleCloseDetails, eventDetails, currentLocale }) => {

  // console.log('eventDetailsModal', eventDetails);

  // const mergedEventProps = { ...eventDetails.extendedProps, id: eventDetails.publicId };

  return (
    <StyledModal show={showDetails} onHide={handleCloseDetails} centered>
      {eventDetails && (
        <>
        <Modal.Header className={`tournament-type-${tournamentTypeToString(eventDetails.type)}`}>
          <FaTimes onClick={handleCloseDetails} className="close-button"/>
          {/* <span class="tournament-type">
            {t(`tournament.${tournamentTypeToString(eventDetails.extendedProps.type)}`)}
          </span> */}
        </Modal.Header>
        <Modal.Body>
          <DetailsCard
            title={eventDetails[`name_${currentLocale}`]}
            tournamentDetails={eventDetails}
            currentLocale={currentLocale}
            structure_file_url={eventDetails[`structure_file_${currentLocale}`]}
            />
        </Modal.Body>
        </>
      )}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
 .modal-content {
  border-radius: 0;
  border: none;
  .date-container {
    padding: 0 1rem;
  }
}
.modal-header {
  position: absolute;
  width: 100%;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 5;
  background-color: transparent;
  padding: 0 0 0 1rem;
  span.tournament-type {
    font-size: 0.5rem;
    position: relative;
    left: 1rem;
    top: 0.2rem;
  }
  &.tournament-type-daily {
    color: var(--fc-tournament-daily);
  }
  &.tournament-type-satellite {
    color: var(--fc-tournament-satellite);
  }
  &.tournament-type-online {
    color: var(--fc-tournament-online);
  }
  &.tournament-type-festival {
    color: var(--fc-tournament-festival);
  }
  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: var(--white);
    font-size: 1.5rem;
    cursor: pointer;
  }
 }
 .modal-body {
  padding: 0;
  border: none;
 }
`;

export default CalendarModal;
