import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveImage from "./ImageWrapper/ResponsiveImage";

const AuthServiceInfo = () => {
  const { t } = useTranslation();

  return (
    <Aside>
      <div>
        <div>
          <ResponsiveImage
            src={"svg/internet-security.svg"}
            alt="internet-security"
          />
          <h3>{t("auth.authService.title")}</h3>
        </div>
        <p>{t("auth.authService.description")}</p>
        <ul>
          <li>
            <ResponsiveImage src={"svg/Check.svg"} alt="check-icon" />
            {t("auth.authService.features.secureInfo")}
          </li>
          <li>
            <ResponsiveImage src={"svg/Check.svg"} alt="check-icon" />
            {t("auth.authService.features.singleSignOn")}
          </li>
          <li>
            <ResponsiveImage src={"svg/Check.svg"} alt="check-icon" />
            {t("auth.authService.features.simplifiedAccess")}
          </li>
        </ul>
      </div>
    </Aside>
  );
};

const Aside = styled.aside`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    max-width: 450px;

    & > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > img {
        width: 100%;
        max-width: 200px;
      }
    }

    & > p {
      margin-top: 0;
    }

    & > ul {
      list-style: none;
      padding: 0;
      font-size: 14px;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      & > li {
        height: 20px;
        line-height: 16px;
        display: flex;
        align-items: flex-start;
        gap: 10px;

        & > img {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    margin-top: 2rem;
    & > div {
      & > div {
        flex-direction: column;
      }

      & > ul {
        & > li {
          height: unset;
          font-size: 14px;
        }
      }
    }
  }
`;

export default AuthServiceInfo;
