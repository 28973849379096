// src/config/navbarLinks.js
export const navbarLinks = [
  {
    title: 'nav.play',
    items: [
      {
        title: 'nav.poker',
        mobileTitle: "nav.playPoker",
        img: 'nav/poker-menu.jpg',
        imgAlt: 'nav.poker',
        items: [
          { categoryTitle: true, route: 'routes.poker', title: 'nav.poker', img: 'nav/poker-menu.jpg', imgAlt: 'nav.poker', icon: 'poker' },
          { route: 'routes.cashGames', title: 'nav.cashGames', icon: 'cashGames' },
          // { categoryTitle: true, route: 'routes.pokerTournamentCalendar', title: 'nav.tournaments' },
          { route: 'routes.pokerTournamentCalendar', title: 'nav.tournamentCalendar', badge: 'buy-tickets', icon: 'tournamentCal' },
          // { route: 'routes.wpt2023_GlobalOctoberMillions', title: 'nav.powerWeeks', icon: 'tournament' },
          { route: 'tournaments.14yearAnniversarySeries2024.schedule', title: 'tournaments.14yearAnniversarySeries2024.title', icon: 'tournament' },
          { route: 'routes.pastEvents', title: 'nav.pastEvents', icon: 'pastEvents' },
          { categoryTitle: true, route: 'routes.eatAndPlay', title: 'nav.eatAndPlay', badge: 'free', icon: 'eatAndPlay' },
          // { route: 'routes.eatAndPlay', title: 'nav.drinksMenu', icon: 'drinks' },
          { route: 'pseudoRoutes.eatAndPlay.cashMenu.path', title: 'nav.cashGameMenu', icon: 'food' },
          { route: 'pseudoRoutes.eatAndPlay.tournamentMenu.path', title: 'nav.tournamentMenu', icon: 'tournament' },
          { categoryTitle: true, route: 'routes.pokerPromotions',title: 'nav.pokerPromotions', icon: 'pokerPromos' },
          { route: 'routes.tlb', title: 'nav.tlb', icon: 'pokerPromo' },
          { route: 'routes.mysteryHighHandNovember2024', title: 'nav.mysteryHighHand', icon: 'pokerPromo' },
          { route: 'routes.badBeatJackpot', title: 'nav.badBeatJackpot', icon: 'pokerPromo' },
          { route: 'routes.pokerPromotions', title: 'nav.allPokerPromotions', icon: 'pokerPromo' }
        ],
      },
      {
        title: 'nav.gamingMachines',
        mobileTitle: "nav.playGamingMachines",
        img: 'nav/egd-menu.jpg',
        imgAlt: 'nav.gamingMachines',
        items: [
          { categoryTitle: true, route: 'routes.egd', title: 'nav.gamingMachines', img: 'nav/gaming-machines.jpg', imgAlt: 'nav.gamingMachines', icon: 'egd'},
          { route: 'routes.egdGameList', title: 'nav.egdGameList', icon: 'gameList' },
          { categoryTitle: true, route: 'routes.electronicTableGames', title: 'nav.electronicTableGames', badge: 'new', icon:'tableGames' },
          { route: 'pseudoRoutes.electronicTableGames.blackJack.path', title: 'nav.blackJack', icon: 'blackJack' },
          { route: 'pseudoRoutes.electronicTableGames.roulette.path', title: 'nav.roulette', icon: 'roulette' },
          { route: 'pseudoRoutes.electronicTableGames.baccarat.path', title: 'nav.baccarat', icon: 'baccarat' },
          // { categoryTitle: true, route: 'routes.eatAndPlay', title: 'nav.eatAndPlay', badge: 'free', icon: 'eatAndPlay' },
          // { route: 'routes.eatAndPlay', title: 'nav.drinksMenu', icon: 'food' },
          // { route: 'routes.eatAndPlay', title: 'nav.bitesMenu', icon: 'drinks' },
          { categoryTitle: true, route: 'routes.egdPromotions', title: 'nav.gamingMachinePromotions', icon: 'egdPromos' },
          // { route: 'routes.freePlayFriday', title: 'nav.freePlayFriday', icon: 'egdPromo' },
          { route: 'routes.chaseTheAce2024', title: 'nav.chaseTheAce', icon: 'egdPromo' },
          { route: 'routes.weeklyJackpotParty', title: 'nav.jackpotParty', icon: 'egdPromo' },
          { route: 'routes.egdPromotions', title: 'nav.allGamingMachinePromotions', icon: 'egdPromo' }
        ]
      }
    ],
  },
  {
    title: 'nav.diningAndLounges',
    route: 'routes.diningLounges',
  },
  {
    title: 'nav.entertainment',
    route: 'routes.entertainment',
  },
  {
    title: 'nav.promotions',
    route: 'routes.promotions',
  },
  {
    title: 'nav.rewards',
    route: 'routes.rewards',
  },
  // {
  //   title: 'nav.shop',
  //   route: 'https://shop.playground.ca/',
  //   isExternal: true,
  // },
];
