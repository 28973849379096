import React, { useState } from "react";
import styled from "styled-components";
import { IoSearch } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button/Button";

const LeaderboardTable = ({ leaderboardData, leaderboardTabs }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputValue = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  // Access the tlbTabs object
  const tlbTabs = t("poker.tlbTabs", { returnObjects: true });
  return (
    <TableContainer>
      <TableHeader>
        {Object.values(leaderboardTabs)?.map((tab) => (
          <Button
            href={tab?.link}
            label={tab?.label}
            key={tab?.label}
            specialHover
          />
        ))}
      </TableHeader>
      <TableDiv>
        <div>
          <div>
            <input
              onChange={handleInputValue}
              type="text"
              placeholder={t("poker.tlb.searchPlaceholder")}
            />
            <IoSearch />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              {Object.values(tlbTabs).map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {leaderboardData?.rows?.map((row, ind) => {
              const values = Object.values(row);
              const searchFieldValue = values[1];

              return searchQuery === "" ||
                (searchFieldValue &&
                  searchFieldValue
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())) ? (
                <tr key={(values[0] || "no-name") + ind}>
                  {" "}
                  {/* Using first value as key fallback */}
                  {Object.keys(row).map((key) => (
                    <td key={key}>{row[key]}</td>
                  ))}
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      </TableDiv>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 100%;
  margin: 20px auto;
  max-width: 1200px;
  background-color: var(--black);
  padding: 40px 20px;
`;

const TableHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  a {
    margin: 0;
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

const TableDiv = styled.div`
  margin: 20px auto;
  width: 100%;
  max-width: 1200px;
  height: fit-content;
  & > div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      width: 100%;
      max-width: 600px;
      position: relative;
      height: auto;
      & > input {
        --webkit-appearance: none;
        border: 1px solid #ccc;
        border-radius: 20px;
        color: #000;
        height: 36px;
        padding: 0 32px 0 12px;
        margin: 0;
        width: 100%;
      }
      & > svg {
        position: absolute;
        width: 20px;
        height: 100%;
        top: 0;
        right: 10px;
        text-align: center;
        cursor: pointer;
        color: var(--grey);
      }
    }
  }
  & > table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    border: 1px solid var(--rewards_grid);
    margin-top: 20px;
    & > thead {
      & > tr {
        & > th {
          background: var(--red);
          color: var(--white);
          font-size: 16px;
          border-bottom: none;
          padding: 8px;
          line-height: 1.42857143;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
    & > tbody {
      & > tr {
        border-top: 1px solid var(--rewards_grid);
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        & > td {
          border-right: 1px solid var(--rewards_grid);
          border-top: none;
          text-align: center;
          color: var(--white);
          font-size: 15px;
          padding: 8px;
          line-height: 1.42857143;
          & > strong {
            color: var(--red);
          }
          &.million {
            background-color: var(--red);
            & > strong {
              color: var(--white);
            }
          }
          &.seats {
            background-color: rgba(255, 255, 255, 0.1);
            & > strong {
              color: var(--white);
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0;
    margin: 0;
    & > div:first-child {
      & > div {
        width: 100%;
        margin: 0 auto;
      }
    }
    & > table {
      & > thead {
        & > tr {
          & > th {
            font-size: 10px;
            padding: 5px;
            &.hide-col {
              display: none;
            }
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            font-size: 10px;
            padding: 5px;
            font-weight: 300;
            &.hide-col {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default LeaderboardTable;
