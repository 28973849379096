import React, { useCallback, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useAccount } from "../context/account/account.provider";
import { send_verification_email } from "../api/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button/Button";
import { t } from "i18next";

const EMAIL_VERIFICATION_DELAY = 2000;

const EmailConfirmationBanner = () => {
  const { access_token, accountInfo } = useAccount();
  const [emailSend, setEmailSend] = useState(false);
  const [closeBanner, setCloseBanner] = useState(true);
  const [isProcessingVerification, setIsProcessingVerification] =
    useState(false);

  const handleChange = (e) => {
    setCodeValue(e.target.value);
  };

  const resetProcessingState = useCallback(() => {
    setTimeout(
      () => setIsProcessingVerification(false),
      EMAIL_VERIFICATION_DELAY
    );
  }, []);

  const handleEmailVerification = () => {
    if (!access_token || isProcessingVerification) return;
    setIsProcessingVerification(true);

    send_verification_email(access_token)
      .then((response) => {
        resetProcessingState();
        if (response.success) {
          setEmailSend(true);
        } else {
          console.error(
            "Failed to send email verification code:",
            response.message
          );
        }
      })
      .catch((error) => {
        resetProcessingState();
        console.error("An error occurred:", error);
      });
  };

  useLayoutEffect(() => {
    if (accountInfo?.meta?.verified_email !== undefined) {
      setCloseBanner(accountInfo.meta.verified_email);
    }
  }, [accountInfo?.meta?.verified_email]);
  return (
    <>
      {!emailSend
        ? !closeBanner && (
            <Banner
              invalid={
                accountInfo?.meta?.verified_email === undefined
                  ? true
                  : accountInfo?.meta?.verified_email
              }
              isProcessingVerification={isProcessingVerification}
            >
              <p>
                <FontAwesomeIcon icon={faEnvelope} aria-hidden="true" />
                &nbsp;
                {t("auth.verifyEmail.title")}
              </p>
              <p className="small">{t("auth.verifyEmail.desc")}</p>
              <ButtonContainer>
                <Button
                  onClick={handleEmailVerification}
                  bgColor="var(--lightblack)"
                  hoverBgColor="var(--red)"
                  hoverColor="var(--white)"
                  disabled={isProcessingVerification}
                  label={t("auth.verifyEmail.sendEmail")}
                />
                <Button
                  label={t("auth.verifyEmail.later")}
                  onClick={() => setCloseBanner(true)}
                  bgColor="var(--white)"
                  color="grey"
                  hoverBgColor="var(--red)"
                  hoverColor="var(--white)"
                />
              </ButtonContainer>
            </Banner>
          )
        : !closeBanner && (
            <Banner
              invalid={
                accountInfo?.meta.verified_email === undefined
                  ? true
                  : accountInfo?.meta.verified_email
              }
              isProcessingVerification={isProcessingVerification}
            >
              <p>{t("auth.verifyEmail.sent")}</p>
            </Banner>
          )}
    </>
  );
};
const Banner = styled.div`
  position: fixed;
  transition: all 0.3s linear;
  bottom: 0;
  background: var(--mutedwhite);
  color: var(--lightblack);
  z-index: 6;
  width: 100%;
  text-align: center;
  height: auto;
  padding: 20px;
  button {
    line-height: 1.2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
  button {
    margin: 0;
  }
  @media (min-width: 768px) {
    flex-direction: row-reverse;
  }
`;
export default EmailConfirmationBanner;
