import { useState } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useAccount } from "../context/account/account.provider";
import { useTranslation } from "react-i18next";
import { useLanguageChanger } from "../middleware/useLanguageChanger";
import { Link } from "react-router-dom";
import ResponsiveImage from "./ImageWrapper/ResponsiveImage";
import InstagramWidget from "./Footer/InstagramWidget";

const Footer = () => {
  const { i18n, t } = useTranslation();
  const [isActive, setIsActive] = useState("");
  const { access_token, logout } = useAccount();
  const date = new Date();
  const year = date.getFullYear();
  const changeLanguage = useLanguageChanger();

  return (
    <FooterDiv id="footer">
      <main>
        <TopDiv isActive={isActive}>
          <div className="explore">
            <button
              className={isActive === "explore" ? "activeHeader" : "none"}
              onClick={() => {
                isActive !== "explore"
                  ? setIsActive("explore")
                  : setIsActive("");
              }}
            >
              {t("footer.explore")}{" "}
              {isActive === "explore" ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
            <Link className="explore" to={t("routes.poker")}>
              {t("footer.poker")}
            </Link>
            <Link className="explore" to={t("routes.egd")}>
              {t("footer.gamingMachines")}
            </Link>
            <Link className="explore" to={`${t("routes.diningLounges")}#${t("diningAndLounges.restaurantsAnchor")}`}>
              {t("footer.restaurants")}
            </Link>
            <Link
              className="explore"
              to={`${t("routes.diningLounges")}#${t("diningAndLounges.loungesAnchor")}`}
            >
              {t("footer.loungeBars")}
            </Link>
            <Link className="explore" to={t("routes.promotions")}>
              {t("footer.promotions")}
            </Link>
            <Link className="explore" to={t("routes.entertainment")}>
              {t("footer.entertainment")}
            </Link>
            <Link className="explore" to={t("routes.playgroundCares")}>
              {t("footer.playgroundCares")}
            </Link>
            <a className="explore" target="_blank"
              href="https://shop.playground.ca/"
            >
              {t("nav.shop")}
            </a>
            {/* <Link className="onlyMobile explore" to={t("routes.playersGuide")}>
              {t("footer.playersGuide")}
            </Link> */}
            {!access_token ? (
              <Link className="explore" to={t("routes.login")}>
                {t("button.sign-in")}
              </Link>
            ) : (
              <>
                <Link
                  className="explore"
                  to={t("routes.myAccount")}
                >
                  {t("footer.myAccount")}
                </Link>
                <Link
                  className="explore"
                  onClick={() => {
                    logout();
                  }}
                >
                  {t("button.sign-out")}
                </Link>
              </>
            )}
          </div>
          <div className="information">
            <button
              className={isActive === "information" ? "activeHeader" : "none"}
              onClick={() => {
                isActive !== "information"
                  ? setIsActive("information")
                  : setIsActive("");
              }}
            >
              {t("footer.information")}{" "}
              {isActive === "information" ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </button>
            <Link className="information" to={t("routes.aboutUs")}>
              {t("footer.aboutUs")}
            </Link>
            <Link className="onlyMobile information" to={t("routes.rewards")}>
              {t("footer.rewards")}
            </Link>
            <Link className="information" to={t("routes.responsibleGaming")}>
              {t("footer.responsibleGaming")}
            </Link>
            <Link className="information" to={t("routes.legal")}>
              {t("footer.legal")}
            </Link>
            <Link className="information" to={t("routes.privacyPolicy")}>
              {t("footer.privacyPolicy")}
            </Link>
            <Link className="information" to={t("routes.pokerTableFilmingPolicy")}>
              {t("footer.filmPolicy")}
            </Link>
            <Link className="information" to={t("routes.careers")}>
              {t("footer.careers")}
            </Link>
            <Link className="information" to={t("routes.blog")}>
              Blog
            </Link>
            <a
              className="information"
              onClick={() =>
                changeLanguage(i18n.language === "en" ? "fr" : "en")
              }
            >
              {i18n.language === "en" ? "Français" : "English"}
            </a>
          </div>
          <div className="my-trip">
            <button
              className={isActive === "myTrip" ? "activeHeader" : "none"}
              onClick={() => {
                isActive !== "myTrip" ? setIsActive("myTrip") : setIsActive("");
              }}
            >
              {t("footer.myTrip")}{" "}
              {isActive === "myTrip" ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
            <Link className="myTrip" to={t("routes.rewards")}>
              {t("footer.rewards")}
            </Link>
            <Link className="myTrip" to={t("routes.playersGuide")}>
              {t("footer.playersGuide")}
            </Link>
            <Link className="myTrip" to={t("routes.visitUs")}>
              {t("footer.visitUs")}
            </Link>
          </div>
          <div className="stay-connected">
            <button
              className={isActive === "stayConnected" ? "activeHeader" : "none"}
              onClick={() => {
                isActive !== "stayConnected"
                  ? setIsActive("stayConnected")
                  : setIsActive("");
              }}
            >
              {t("footer.stayConnected")}{" "}
              {isActive === "stayConnected" ? (
                <IoIosArrowUp />
              ) : (
                <IoIosArrowDown />
              )}
            </button>
            <a
              className="stayConnected"
              href="https://www.facebook.com/PlaygroundYUL"
              target="_blank"
              rel="noreferrer"
            >
              <ResponsiveImage
                src={"svg/Facebook_footer.svg"}
                alt="facebook-icon"
              />{" "}
              Facebook
            </a>
            <a
              className="stayConnected"
              href="https://www.instagram.com/playgroundyul/"
              target="_blank"
              rel="noreferrer"
            >
              <ResponsiveImage
                src={"svg/Instagram_footer.svg"}
                alt="instagram-icon"
              />{" "}
              Instagram
            </a>
            <a
              className="stayConnected"
              href="https://www.youtube.com/channel/UChwagnt5l78lyylCAmLpgmw"
              target="_blank"
              rel="noreferrer"
            >
              <ResponsiveImage
                src={"svg/Youtube_footer.svg"}
                alt="youtube-icon"
              />{" "}
              Youtube
            </a>
            <a
              className="stayConnected"
              href="https://twitter.com/PlaygroundPoker"
              target="_blank"
              rel="noreferrer"
            >
              <ResponsiveImage src={"svg/x_footer.svg"} alt="twitter-icon" /> X
            </a>
          </div>
          <div className="contact-us">
            <button
              className={isActive === "contactUs" ? "activeHeader" : "none"}
              onClick={() => {
                isActive !== "contactUs"
                  ? setIsActive("contactUs")
                  : setIsActive("");
              }}
            >
              {t("footer.contactUs")}{" "}
              {isActive === "contactUs" ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
            <Link className="contactUs" to={`${t("routes.visitUs")}#map`}>
              <ResponsiveImage
                src={"svg/Locationpin.svg"}
                alt="location-icon"
              />{" "}
              1500 Route 138, Kahnawake, <br/>QC J0L 1B0
            </Link>
            <a className="contactUs" href="tel:14506357653">
              <ResponsiveImage src={"svg/Phonecall.svg"} alt="phone-icon" />{" "}
              +1-450-635-7653
            </a>
            <a className="contactUs" href="tel:18556857653">
              <ResponsiveImage src={"svg/Phonecall.svg"} alt="phone-icon" />{" "}
              +1-855-685-7653
            </a>
            <a className="contactUs" href="mailto:info@playground.ca">
              <ResponsiveImage src={"svg/Email.svg"} alt="email-icon" />{" "}
              info@playground.ca
            </a>
          </div>
        </TopDiv>

        <InstagramWidget />

        <CenterDiv>
          <a href={t("diningAndLounges.dining.theRail.link")} target="_blank">
            <ResponsiveImage src={"svg/RAIL-LOGO-FOOTER.svg"} alt="rail-logo" />
          </a>
          <a href={t("diningAndLounges.dining.drunkenDragon.link")} target="_blank">
            <ResponsiveImage
              src={"svg/OROCHI-LOGO-FOOTER.svg"}
              alt="orochi-logo"
            />
          </a>
          <a href={t("diningAndLounges.lounges.esperanto.link")} target="_blank">
            <ResponsiveImage
              src={"svg/ESPERANTO-LOGO-FOOTER.svg"}
              alt="esperanto-logo"
            />
          </a>
          <a href={t("diningAndLounges.lounges.babel.link")} target="_blank">
            <ResponsiveImage
              src={"svg/BABEL-LOGO-FOOTER.svg"}
              alt="babel-logo"
            />
          </a>
          <a href={t("diningAndLounges.dining.etc.link")} target="_blank">
            <ResponsiveImage src={"svg/ETC_logo_footer.svg"} alt="etc-logo" />
          </a>
        </CenterDiv>
        <SocialIcons>
          <a
            className="stayConnected"
            href="https://www.facebook.com/PlaygroundYUL"
            target="_blank"
            rel="noreferrer"
          >
            <ResponsiveImage
              src={"svg/Facebook_footer.svg"}
              alt="facebook-icon"
            />{" "}
            Facebook
          </a>
          <a
            className="stayConnected"
            href="https://www.instagram.com/playgroundyul/"
            target="_blank"
            rel="noreferrer"
          >
            <ResponsiveImage
              src={"svg/Instagram_footer.svg"}
              alt="instagram-icon"
            />{" "}
            Instagram
          </a>
          <a
            className="stayConnected"
            href="https://www.youtube.com/channel/UChwagnt5l78lyylCAmLpgmw"
            target="_blank"
            rel="noreferrer"
          >
            <ResponsiveImage
              src={"svg/Youtube_footer.svg"}
              alt="youtube-icon"
            />{" "}
            Youtube
          </a>
          <a
            className="stayConnected"
            href="https://twitter.com/PlaygroundPoker"
            target="_blank"
            rel="noreferrer"
          >
            <ResponsiveImage src={"svg/x_footer.svg"} alt="twitter-icon" /> X
          </a>
        </SocialIcons>
        <BottomDiv>
          <p>{t("footer.footerText")}</p>
          <p>&#169; {t("footer.copyright", { year })}</p>
          <a
            href="https://gamingcommission.ca/"
            target="_blank"
            rel="noreferrer"
          >
            <ResponsiveImage
              src={"gaming-commision.png"}
              alt="gambling-commission"
            />
          </a>
        </BottomDiv>
      </main>
    </FooterDiv>
  );
};
const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
    transform: translateX(-50%);
  }

  to {
    transform: scale(1);
    transform: translateX(0);
    opacity: 1;
  }
`;
const FooterDiv = styled.div`
  height: auto;
  padding: 100px 0 20px 0;
  width: 100%;
  background: var(--black);
  color: var(--white);
  & > main {
    padding: 0 20px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-flow: column wrap;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 10px 0 60px 0;
    & > main {
      padding: 0;
      width: 100%;
      margin: 0;
    }
  }
`;

const TopDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  & > div {
    display: flex;
    flex-flow: column wrap;
    padding-bottom: 15px;
    height: auto;

    & > button {
      font-size: 20px;
      margin: 0;
      padding: 0 20px 10px; /* Matching padding with h2 */
      text-transform: uppercase;
      font-weight: 300;
      background: none;
      border: none;
      text-align: left;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      color: var(--white);
      @media screen and (min-width: 992px) {
        cursor: default;
        border-bottom: none!important;
        margin-bottom: 0!important;
      }
      & > svg {
        display: none;
      }

      &.activeHeader {
        border-bottom: 2px solid var(--lightblack);
        margin-bottom: 10px;
      }

      &:hover {
        color: var(--white); /* Matching hover effect */
      }
    }

    & > a {
      display: flex;
      gap: 10px;
      padding: 8px 20px;
      font-size: 15px;
      line-height: 20px;
      color: var(--mutedwhite);
      transition: 0.4s all;
      height: auto;
      width: 100%;
      max-width: max-content;
      align-items: center;
      user-select: none;
      opacity: 0.8;

      & > img {
        height: 20px;
        width: 20px;
      }

      &:hover {
        opacity: 1;
        color: var(--red);
      }
    }

    .onlyMobile {
      display: none;
    }
  }

  & > div:last-child a:nth-child(2) img {
    align-self: flex-start;
  }

  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;

    & > div {
      padding-bottom: 10px;

      & > button {
        font-size: 20px;
        margin: 0;
        border-bottom: 2px solid var(--lightblack);
        padding: 0 20px 10px; /* Matching padding with h2 */
        font-weight: 300;
        position: relative;
        width: 100%;
        text-align: left;

        &.activeHeader {
          margin-bottom: 10px;
        }

        & > svg {
          display: inline-block;
          position: absolute;
          top: 5px;
          right: 20px;
        }
      }

      & > a {
        display: none;
        transition: ease 0.4s;
        animation: ${fadeIn} 0.3s ease-in-out;
      }

      ${(props) => css`
        .${props.isActive} {
          display: flex;
        }
      `}
    }

    & > div {
      &.stay-connected,
      &.my-trip {
        display: none;
      }
    }
  }
`;

const CenterDiv = styled.div`
  margin: 20px auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2%;
  padding: 20px 0;
  & > a {
    height: 70px;
    width: auto;
    justify-self: center;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      object-fit: contain;
      width: auto;
      height: 70px;
    }
  }
  & > a:nth-child(4) {
    & > img {
      max-width: 150px;
    }
  }
  & > a:last-child {
    & > img {
      height: 50px;
      max-width: 250px;
    }
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
    border-top: none;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
    margin: 0;
    padding: 30px;
    justify-items: center;
    align-items: center;
    & > a {
      width: 100%;
    }
    & > a:nth-child(4) {
      grid-column-start: 1;
      grid-row-start: 2;
    }
    & > a:last-child {
      grid-column-start: 3;
      grid-row-start: 2;

      & > img {
        height: 40px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
    border-top: none;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
    margin: 0;
    padding: 15px 20px 30px 20px;
    & > a {
      & > img {
        width: 100%;
      }
    }
    & > a:last-child {
      grid-column: span 2;
      & > img {
        height: 40px;
        grid-row: span 2;
      }
    }
  }
`;
const BottomDiv = styled.div`
  color: var(--grey);
  text-align: center;
  & > p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    margin: 8px 0;
    width: 100%;
  }
  & > a {
    & > img {
      margin: 0 auto;
      width: 150px;
      height: auto;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone styles */
    & > p {
      font-size: 12px;
      padding: 0 20px;
    }
  }
`;
const SocialIcons = styled.div`
  display: none;
  @media screen and (max-width: 991px) {
    border-bottom: 1px solid var(--grey);
    border-top: 1px solid var(--grey);
    width: calc(100% - 40px);
    display: inline-flex;
    padding: 20px;
    margin: auto;
    & > a {
      font-size: 0;
      font-weight: 200;
      transition: 0.4s all;
      height: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      & > img {
        height: 40px;
        width: 100%;
        object-fit: contain;
      }
      &:hover {
        color: var(--red);
      }
    }
  }
`;
export default Footer;
