import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import Hero from "src/components/Hero/Hero";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const playgroundBuffets = () => {
  const { i18n, t } = useTranslation();
  const responsiveBgImage = useResponsiveImageUrl("about/about-background.jpg");


  return (
    <Wrapper>
      <Hero
        bgColor=""
        bgImage="promotions/dining/american-breakfast-buffet/American-Breakfast-Buffet-HEADER-2560X800.jpg"
        buttonLink=""
        buttonText=""
        description=""
        descriptionBgColor=""
        image={`promotions/dining/american-breakfast-buffet/AMERICAN-BREAKFAST-BUFFET-LOGO-${i18n.language}_4.png`}
        imageAlt={t("diningPromos.playgroundBuffets.breakfast_5.title")}
        // mainHeroText={t("diningPromos.playgroundBuffets.hero.title")}
        title=""
      >
        <h1>{t("diningPromos.playgroundBuffets.hero.title")}</h1>
        <h2>{t("diningPromos.playgroundBuffets.hero.secondfloor")}</h2>
      </Hero>
      <MainContentDiv bgImage={responsiveBgImage}>
        <container-wide>
          <Row xs={1} md={2} className="g-4">
            <Col>
              <ResponsiveImage
                src="promotions/American-Buffet-Image-FR.jpg?1726313239"
                alt={t("diningPromos.playgroundBuffets.breakfast_5.imageAlt")}
              />
            </Col>
            <Col>
              <SubTitleSection
                subTitle={t("diningPromos.playgroundBuffets.breakfast_5.title")}
              />
              <h3 className="price bolder">
                {t("diningPromos.playgroundBuffets.breakfast_5.price")}
              </h3>
              <p>
                <strong>
                  {t("diningPromos.playgroundBuffets.breakfast_5.time")}
                </strong>
              </p>
              <ReactMarkdown>
                {t("diningPromos.playgroundBuffets.breakfast_5.description")}
              </ReactMarkdown>
            </Col>
          </Row>

          {/* <Row className="g-4">
            <Col xs={12} md={6}>
              <ResponsiveImage
                src={t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.image")}
                alt={t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.imageAlt")}
              />
            </Col>

            <Col xs={12} md={6}>
              <SubTitleSection subTitle={t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.title")} />

              <p><strong>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.time")}</strong></p>

              <SubTitleSection as={'h3'} subTitle={t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.pricingTitle")} />
              <Table>
                <thead>
                  <tr>
                    <th>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.level")}</th>
                    <th>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.price")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("myAccount.white")}</td>
                    <td>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.pricing.white")}</td>
                  </tr>
                  <tr>
                    <td>{t("myAccount.black")}</td>
                    <td>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.pricing.black")}</td>
                  </tr>
                  <tr>
                    <td>{t("myAccount.red")}</td>
                    <td>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.pricing.red")}</td>
                  </tr>
                  <tr>
                    <td>{t("myAccount.gold")}</td>
                    <td>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.pricing.gold")}</td>
                  </tr>
                  <tr>
                    <td>{t("myAccount.silver")}</td>
                    <td>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.pricing.silver")}</td>
                  </tr>
                  <tr>
                    <td>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.nonMembers")}</td>
                    <td>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.pricing.nonMembers")}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xs={12}>
              <SubTitleSection as={'h3'} subTitle={t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.stations.title")} />

              <p>{t("diningPromos.playgroundBuffets.vegas_deluxe_buffet.stations.list")}</p>
            </Col>
          </Row> */}

          {/* Stations Section */}
          {/* <Row>
            <Col>
            </Col>
          </Row> */}
        </container-wide>
      </MainContentDiv>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .hero_container {
    min-height: 500px;
    background-size: cover;
  }
  @media (max-width: 768px) {
    .hero_container {
      min-height: 350px;
    }
  }
`;

const MainContentDiv = styled.div`
  @media (min-width: 1200px) {
    padding-bottom: 3rem;
  }
  background: #000 url(${(props) => props.bgImage}) 50% 50% fixed no-repeat;
  background-size: cover;
  color: var(--white);
  .row {
    padding: 3rem 2rem;
    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.5);
      flex-direction: row-reverse;
    }
    .col {
      .subtitle-section {
        @media (min-width: 768px) {
          padding-top: 0;
        }
      }
      button {
        margin: 1.2rem 0;
      }
    }
    .price {
      font-size: 3rem;
      color: #ff004e;
    }
  }
`;

const Section = styled.section`
  padding: 2rem 0;
  h2,
  h3 {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 0.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  thead {
    background-color: #eee;
    color: var(--black);
  }
  th,
  td {
    padding: 0.75rem;
    text-align: left;
    border: 1px solid #ddd;
  }
`;

const Disclaimer = styled.p`
  margin: 0 0 2rem;
`;

export default playgroundBuffets;
