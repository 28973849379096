import styled from "styled-components";
// import HomePageHero from "../components/HomePage/HomePageHero";
import VideoComponent from "../components/HomePage/VideoComponent";
import JoinTheClub from "../components/HomePage/JoinTheClub";
// import Merch from "../components/Shop/Merch";
import TitleSection from "../components/TitleSection/TitleSection";
import MoreThanShows from "../components/HomePage/MoreThanShows";
import BbjHero from "../components/Poker/BbjHero";
import { useHomepage } from "../context/homepage/homepage.provider";
import { t } from "i18next";

const HomePage = () => {
  const { bbjData } = useHomepage();
  return (
    <HomePageDiv>
      {/* <HomePageHero /> */}
      <VideoHolder>
        <VideoComponent />
      </VideoHolder>
      <TitleSection
        title={t("homepage.welcome-to-pg")}
        text={t("homepage.welcome-to-pg-text")}
      />
      <MoreThanShows />
      <BbjHero bbjData={bbjData} compact />
      <JoinTheClub />
      {/* <Merch /> */}
    </HomePageDiv>
  );
};

const HomePageDiv = styled.div`
  width: 100%;
`;

const VideoHolder = styled.div`
  margin-top: 80px;
  width: 100%;
  position: relative;
  /* Placeholder to maintain aspect ratio */
  padding-bottom: 75%; /* 4:3 aspect ratio for mobile */
  @media (min-width: 992px) {
    margin-top: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio for desktop */
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default HomePage;
