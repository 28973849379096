import React from "react";
import styled, { keyframes } from "styled-components";
import { ImSpinner2 } from "react-icons/im";

const Spinner = ({ variant = "light" }) => {
  return (
    <SpinnerCont variant={variant}>
      <ImSpinner2 />
    </SpinnerCont>
  );
};

const rotateRight = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerCont = styled.span`
  svg {
    color: ${({ variant }) => (variant === "dark" ? "var(--mutedwhite)" : "var(--lightblack)")};
    animation: ${rotateRight} 2s linear infinite;
  }
`;

export default Spinner;
