import styled from "styled-components";
import { useState } from "react";
import { reset_password } from "../../api/auth";
import { useTranslation } from "react-i18next";
import { StyledForm } from '../../GlobalStyle';
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Button from "src/components/Button/Button";
import AuthServiceInfo from "src/components/AuthServiceInfo";
import TopLogo from "src/components/Auth/TopLogo";


const ResetPassword = () => {
  const { i18n, t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const payload = {
      new_password: formData.get("new_password"),
      new_password_confirmation: formData.get("new_password_confirmation"),
    };

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const response = await reset_password(payload);

      if (response.success) {
        window.location.href = t("routes.login");
      } else {
        if (Array.isArray(response.messages) && response.messages.length > 0) {
          setErrorMessage(response.messages.join(" "));
        }
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      setErrorMessage(
        i18n.language === "en"
          ? "Error resetting password"
          : "Erreur de réinitialisation du mot de passe"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ResetPasswordDiv>
      <ResetPasswordMain>
        <StyledForm onSubmit={handleSubmit}>
          <TopLogo />
          <FormGroup controlId="new_password">
            <FormLabel>
              {t("auth.form.newPassword.label")}
            </FormLabel>
            <FormControl
              type="password"
              id="new_password"
              name="new_password"
              placeholder={t("auth.form.newPassword.placeholder")}
              required
            />
            <Form.Control.Feedback type="invalid">
              {t("auth.form.newPassword.requiredMessage")}
            </Form.Control.Feedback>
          </FormGroup>
          <FormGroup controlId="new_password_confirmation">
            <FormLabel>
              {t("auth.form.confirmNewPassword.label")}
            </FormLabel>
            <FormControl
              type="password"
              id="new_password_confirmation"
              name="new_password_confirmation"
              placeholder={t("auth.form.confirmNewPassword.placeholder")}
              required
            />
          </FormGroup>
          <Form.Control.Feedback type="invalid">
              {t("auth.form.confirmNewPassword.requiredMessage")}
            </Form.Control.Feedback>
          <Button
            type="submit"
            disabled={isLoading}
            bgColor="var(--red)"
            color="var(--white)"
            hoverColor="var(--black)"
            hoverBgColor="var(--white)"
            fluid
            label={
              isLoading ? (
                <Spinner as="span" animation="border" size="sm" />
              ) : (
                t("submit")
              )
            }
          />
          <Link to={t("routes.homepage")} className="link" >{t("button.return-back-home")}</Link>
        </StyledForm>
        <AuthServiceInfo />
      </ResetPasswordMain>
    </ResetPasswordDiv>
  );
};

const ResetPasswordDiv = styled.div`
  background: var(--black);
  color: var(--white);
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  position: relative;
`;

const ResetPasswordMain = styled.main`
  background: var(--lightblack);
  height: auto;
  width: 100%;
  max-width: 1440px;
  margin: 50px auto;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 991px) {
    margin: 0;
    border-radius: 0;
    width: 100%;
    grid-template-columns: 1fr;
    height: 100vh;
  }
`;

export default ResetPassword;
