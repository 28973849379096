import React, { useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import useResponsiveImageUrl from "../utils/useResponsiveImageUrl";

const Careers = () => {
  const { t } = useTranslation();
  const iframeRef = useRef(null);
  const responsiveBgImage = useResponsiveImageUrl("careers/career-fair-2023.jpg");

  const resizeResumatorIframe = (height, nojump) => {
    if (nojump === 0) {
      window.scrollTo(0, 0);
    }

    if (iframeRef.current) {
      iframeRef.current.style.height = `${parseInt(height) + 20}px`;
    }
  };

  // Use effect to attach the function to window only once on mount
  useEffect(() => {
    // Assuming 'resizeResumatorIframe' might be called from somewhere else, we attach it to the window
    window.resizeResumatorIframe = resizeResumatorIframe;

    // Cleanup function to remove the attached function
    return () => {
      delete window.resizeResumatorIframe;
    };
  }, []);
  return (
    <>
      <CareersDiv bgImage={responsiveBgImage}>
        <div>
          <div>
            <h1>{t("careers.header-1")}</h1>
            <h2>{t("careers.header-2")}</h2>
            {/* <ContentSectionLink to="/careers/career-fair">
              <div>
                <span>Learn More</span>
              </div>
            </ContentSectionLink> */}
            <div>
              <p>{t("careers.content-header")}</p>
            </div>
          </div>
        </div>
        <div>
          <h2>{t("careers.content-title")}</h2>
          <span>{""}</span>
          <div>
            <iframe
              title="UniqueTitleForIframe"
              name="resumator-job-frame"
              id="resumator-job-frame"
              className="resumator-advanced-widget"
              src="//playground.applytojob.com/apply/jobs/"
              width="100%"
              height="3658"
              scrolling="yes"
              frameBorder="0"
              allowtransparency="true"
              ref={iframeRef}
            ></iframe>
          </div>
        </div>
        
        
      </CareersDiv>
    </>
  );
};
const CareersDiv = styled.div`
  width: 100%;
  height: auto;
  color: var(--white);
  background: var(--black);
  & > div:first-child {
    position: relative;
    z-index: 0;
    height: auto;
    width: 100%;
    background: url(${(props) => props.bgImage}) no-repeat;
    background-position: 70% 50%;
    padding: 270px 0px 270px;
    border: none;
    background-repeat: no-repeat;
    background-size: cover;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > div {
      width: 100%;
      max-width: 1100px;
      margin: 0 auto;
      text-align: left;
      position: relative;
      min-height: 1px;
      & > h1:first-child {
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 100px;
        font-size: 116px;
        text-align: left;
        color: var(--white);
      }
      & > h2:nth-child(2) {
        text-transform: uppercase;
        margin: 0;
        font-size: 87px;
        font-weight: 700;
        line-height: 66px;
        padding-bottom: 20px;
        color: var(--white);
      }
      & > div {
        text-align: center;
        margin-top: 10px;
        width: 100%;
        padding: 15px 20px;
        max-width: 750px;
        height: auto;
        background: var(--white);
        color: var(--black);
        & > p {
          margin: 0;
          font-size: 27px;
          font-weight: bold;
          padding: 0;
          line-height: 34px;
          display: inline-block;
          margin: 0 auto;
          text-align: center;
          color: var(--red);
        }
      }
    }
  }
  & > div:nth-child(2) {
    margin: 40px auto 0 auto;
    height: auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    & > h2 {
      text-align: center;
      font-weight: bold;
      margin: 0;
    }
    & > span {
      margin: 0 auto;
      margin-top: 10px;
      width: 100%;
      max-width: 270px;
      border-bottom: 2px solid var(--red);
    }
    & > div {
      margin: 20px 0;
      width: 100%;
      height: auto;
      background: var(--lightblack);
      padding: 20px;
      & > iframe {
        height: 3300px;
      }
    }
  }
  @media screen and (max-width: 990px) {
    min-height: 100vh;
    padding-top: 80px;
    & > div:first-child {
      height: auto;
      padding: 200px 0 20px 0;
      overflow-y: visible;
      background-size: contain;
      background-position: top;
      top: 0;
      & > div {
        padding: 0 20px;
        position: relative;
        top: -40px;
        max-width: 600px;
        margin: 0;
        & > h1:first-child {
          line-height: 50px;
          font-size: 50px;
          font-weight: 700;
          margin: 0;
        }
        & > h2:nth-child(2) {
          line-height: 48px;
          font-size: 28px;
          font-weight: 700;
          padding: 0;
        }
        & > div {
          & > p {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
    & > div:nth-child(2) {
      padding: 0;
      & > div {
        & > iframe {
          height: 3000px;
        }
      }
    }
  }
`;
// const ContentSectionLink = styled(Link)`
//   line-height: 29px;
//   height: 50px;
//   text-align: center;
//   display: block;
//   max-width: 300px;
//   width: 100%;
//   cursor: pointer;
//   position: relative;
//   z-index: 2;
//   transition: all 0.5s;
//   position: relative;
//   border-radius: 5px;
//   padding: 10px 10px;
//   margin: 20px auto;
//   font-weight: 600;
//   font-size: 20px;
//   color: var(--black);
//   &:hover {
//     color: var(--white);
//   }
//   &::before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: -1;
//     transition: all 0.5s;
//     color: var(--black);
//     background: var(--white);
//     border-radius: 5px;
//   }
//   &:hover::before {
//     opacity: 0;
//     transform: scale(0.5, 0.5);
//   }
//   &::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 1;
//     opacity: 0;
//     transition: all 0.5s;
//     border: 2px solid var(--white);
//     transform: scale(1.2);
//     border-radius: 5px;
//   }
//   &:hover::after {
//     opacity: 1;
//     transform: scale(1, 1);
//   }
// `;
export default Careers;
