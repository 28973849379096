/**
 * formatAmount
 *
 * Formats a given value as an amount with currency symbol based on the specified language.
 *
 * @param {number} value - The value to format as an amount.
 * @param {string} language - The language to use for formatting. Valid values are "en" for English and any other language supported by the browser's locale.
 * @param {boolean} currency - A flag to indicate whether to include the currency symbol. Default is true.
 * @returns {string} The formatted amount with currency symbol.
 *
 * @example
 * const formattedAmount = formatAmount(1234.56, "en");
 * // formattedAmount is "$1,234"
 * @example
 * const formattedAmount = formatAmount(1234.56, "fr");
 * // formattedAmount is "1 234$"
 * @example
 * const formattedAmount = formatAmount(1234.56, "en", false);
 * // formattedAmount is "1,234"
 * @example
 * const formattedAmount = formatAmount(1234.56, "fr", false);
 * // formattedAmount is "1 234"
 * @example
 * const formattedAmount = formatAmount(1234.56, "en", true, false);
 * // formattedAmount is "$1,234.56"
 */

export const formatAmount = (value, language, currency = true, round = true) => {
  if (value === 0) {
    return currency
      ? round
        ? language === "en"
          ? "$0"
          : "0$"
        : language === "en"
        ? "$0.00"
        : "0.00$"
      : "0";
  }
  if (!value) return "";
  const amountNumber = parseFloat(value);
  if (isNaN(amountNumber)) return "";

  const roundedAmount = round ? Math.floor(amountNumber) : amountNumber;
  const formattedAmount = round
    ? roundedAmount.toLocaleString(language)
    : roundedAmount.toLocaleString(language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

  if (currency) {
    return language === "en" ? `$${formattedAmount}` : `${formattedAmount}$`;
  } else {
    return formattedAmount;
  }
};


/**
 * formatDate
 *
 * Formats a given date string based on the specified language.
 *
 * @param {string} dateString - The date string to format.
 * @param {string} language - The language to use for formatting. Valid values are "en" for English and any other language supported by the browser's locale.
 * @param {boolean|string} includeWeekday - A flag to indicate whether to include the weekday name. "short" for abbreviated weekday name. Default is false.
 * @returns {string} The formatted date string.
 *
 * @example
 * const formattedDate = formatDate("2022-01-01", "en");
 * // formattedDate is "January 1, 2022"
 * @example
 * const formattedDate = formatDate("2022-01-01", "fr");
 * // formattedDate is "01 janvier 2022"
 * @example
 * const formattedDate = formatDate("2022-01-01", "en", true);
 * // formattedDate is "Saturday, January 1, 2022"
 * @example
 * const formattedDate = formatDate("2022-01-01", "fr", true);
 * // formattedDate is "samedi 01 janvier 2022"
 * @example
 * const formattedDate = formatDate("2019-01-01T00:00:00.000-05:00", "en", true);
 * // formattedDate is "Tuesday, January 1, 2019"
 */
export const formatDate = (dateString, language, includeWeekday = false) => {
  if (!dateString) return "";

  let date;

  // Check if the date string is in ISO format with time component
  if (dateString.includes('T')) {
    date = new Date(dateString);
  } else {
    // Manually parse simple date strings like "YYYY-MM-DD"
  const [year, month, day] = dateString.split('-').map(Number);
    date = new Date(year, month - 1, day); // Correct zero-based month
  }

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid Date");
    return "Invalid Date";
  }

  // Options for formatting the date
  const options = { year: 'numeric', month: 'long', day: '2-digit' };

  if (includeWeekday) {
    options.weekday = 'long';
    if (includeWeekday === 'short') {
      options.weekday = 'short';
    }
  }

  // Format the date using the specified language
  return date.toLocaleDateString(language, options);
};

/**
 *
 * @param {string} dateTimeString - The date and time string to format.
 * @param {string} language - The language to use for formatting. Valid values are "en" for English and any other language supported by the browser's locale.
 * @returns {string} The formatted time string.
 * @example
 * const formattedTime = formatTime("2022-01-01T12:00:00", "en");
 * // formattedTime is "12:00 PM"
 */
export const formatTime = (dateTimeString, language) => {
  if (!dateTimeString) return "";

  const date = new Date(dateTimeString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid Date");
    return "";
  }

  // Format the time using the specified language
  return date.toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit' });
}


/**
 * toUrlFriendlyString
 *
 * Converts a given string to a URL-friendly format.
 *
 * @param {string} str - The input string to convert.
 * @returns {string} The URL-friendly string.
 *
 * @example
 * const urlString = toUrlFriendlyString("Café de l'Amour");
 * // urlString is "cafe-de-l-amour"
 */
export const toUrlFriendlyString = (str) => {
  return str
    .normalize("NFD")         // Normalize to decompose combined letters
    ?.replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
    .toLowerCase()            // Convert to lowercase
    ?.replace(/\s+/g, '-')     // Replace spaces with hyphens
    ?.replace(/[^\w-]+/g, ''); // Remove all non-word characters except hyphens
};

/**
 * Normalize the given pathname by removing any trailing slashes or hash fragments.
 * This helps in ensuring consistent comparison of URLs for routing and SEO purposes.
 *
 * Examples:
 * - "/about/" becomes "/about"
 * - "/#section" becomes "/"
 * - "/contact/#section" becomes "/contact"
 * - "/products//" becomes "/products"
 *
 * @param {string} pathname - The URL pathname to normalize.
 * @returns {string} - The normalized pathname without trailing slashes or hash fragments.
 */
export const normalizePathname = (pathname) => {
  // Ensure pathname is a string before applying replace
  if (typeof pathname === 'string') {
    return pathname?.replace(/\/+$/, '')?.replace(/#.*$/, '');
  } else {
    // console.error('Expected a string but received:', pathname);
    return '';
  }
};

/**
 * toTitleCase
 * This function converts the first character of each word in a string to uppercase and the rest to lowercase.
 * @param {string} str - The input string to convert.
 * @returns {string} The title-cased string.
 * @example
 * const title = toTitleCase("john DOE");
 * // title is "John Doe"
 */
export const toTitleCase = (str) => {
  if (typeof str !== 'string') {
    console.warn('Warning: Expected a string input, but received:', typeof str);
    return ''; // Return empty string if input is not a string
  }

  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
