import React, { useState } from "react";
import {
  Form,
  InputGroup,
  FormControl,
  Alert,
  Row,
  Col,
  FormLabel,
  FormGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StyledForm as GlobalStyledForm } from "../../GlobalStyle";
import {
  IoCalendar,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoTwitch,
  IoLogoTwitter,
  IoAddCircle,
  IoTrash,
  IoLogoTiktok,
} from "react-icons/io5";
import styled from "styled-components";
import Button from "src/components/Button/Button";
import { sendFilmingConsentAPI } from "../../api/poker";
import { useAccount } from "../../context/account/account.provider";
import { getTodayInEasternTime } from "../../utils/timeUtils";

const PokerTableFilmingPolicy = () => {
  const { t, i18n } = useTranslation();
  const { accountInfo } = useAccount();
  const navigate = useNavigate();

  // Form state management
  const [formValues, setFormValues] = useState({
    facebookHandle: "",
    instagramHandle: "",
    youtubeHandle: "",
    twitchHandle: "",
    xHandle: "",
    tiktokHandle: "",
    dates: [""],
    consent: false,
  });

  const [dateFields, setDateFields] = useState([""]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Add additional date fields
  const addDateField = () => {
    if (dateFields.length < 10) {
      setDateFields([...dateFields, ""]);
    } else {
      alert(t("pokerTableFilmingPolicy.maxDateFields"));
    }
  };

  // Remove date fields
  const removeDateField = (index) => {
    const newDates = [...dateFields];
    if (newDates.length > 1) {
      newDates.splice(index, 1);
      setDateFields(newDates);
      setFormValues({ ...formValues, dates: newDates });
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle date changes
  const handleDateChange = (index, e) => {
    const newDates = [...dateFields];
    newDates[index] = e.target.value;
    setDateFields(newDates);
    setFormValues({ ...formValues, dates: newDates });
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Check if user is signed in
    if (!accountInfo) {
      setErrorMessage(t("pokerTableFilmingPolicy.signInFirst"));
      setIsSubmitting(false);
      return;
    }

    try {
      // Create a FormData object and append form values
      const formData = new FormData();
      formData.append("facebookHandle", formValues.facebookHandle);
      formData.append("instagramHandle", formValues.instagramHandle);
      formData.append("youtubeHandle", formValues.youtubeHandle);
      formData.append("twitchHandle", formValues.twitchHandle);
      formData.append("xHandle", formValues.xHandle);
      formData.append("tiktokHandle", formValues.tiktokHandle);
      formData.append("consent", formValues.consent.toString());
      formValues.dates.forEach((date) => {
        formData.append("dates[]", date);
      });

      // Call the API with the FormData object
      const response = await sendFilmingConsentAPI(formData);
      // Handle the API response
      if (!response.success) {
        setErrorMessage(response.data.toString());
        setIsSubmitting(false);
      } else {
        setFormSubmitted(true);
        setTimeout(() => {
          // setIsSubmitting(false);
          // setFormSubmitted(false);
          // navigate(t("routes.homepage"));
        }, 2000);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setErrorMessage(t("pokerTableFilmingPolicy.errorMessage"));
      setIsSubmitting(false);
    }
  };

  // Render the form
  return (
    <>
      <Div>
        <div>
          <h1 className="text-center my-4">
            {t("pokerTableFilmingPolicy.filmingPolicyTitle")}
          </h1>
          <ContentDiv>
            <p>{t("pokerTableFilmingPolicy.introText")}</p>
            <ol>
              <li>
                <h3>{t("pokerTableFilmingPolicy.permissionToFilm")}</h3>
                <ul>
                  {t("pokerTableFilmingPolicy.permissionToFilmDetails", {
                    returnObjects: true,
                  }).map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </li>
              <li>
                <h3>{t("pokerTableFilmingPolicy.contentReviewAndAuditing")}</h3>
                <ul>
                  {t(
                    "pokerTableFilmingPolicy.contentReviewAndAuditingDetails",
                    {
                      returnObjects: true,
                    }
                  ).map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </li>
              <li>
                <h3>{t("pokerTableFilmingPolicy.prohibitedActions")}</h3>
                <ul>
                  {t("pokerTableFilmingPolicy.prohibitedActionsDetails", {
                    returnObjects: true,
                  }).map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </li>
              <li>
                <h3>{t("pokerTableFilmingPolicy.consequencesForBreach")}</h3>
                <ul>
                  {t("pokerTableFilmingPolicy.consequencesForBreachDetails", {
                    returnObjects: true,
                  }).map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </li>
              <li>
                <h3>{t("pokerTableFilmingPolicy.platformCompliance")}</h3>
                <ul>
                  {t("pokerTableFilmingPolicy.platformComplianceDetails", {
                    returnObjects: true,
                  }).map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </li>
            </ol>
          </ContentDiv>
            <StyledForm onSubmit={handleSubmit} disabled={!accountInfo}>

              {/* Display user's first name and player ID */}
              {!accountInfo ? (
                <Alert variant="info">
                    {t("pokerTableFilmingPolicy.signInFirst")}
                </Alert>
              ) : (
                <Row>
                  <Col md={6} className="mb-3">
                    <FormGroup>
                      <FormLabel>
                        {t("pokerTableFilmingPolicy.firstName")}
                      </FormLabel>
                      <p>{accountInfo?.data.name}</p>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <FormLabel>
                        {t("pokerTableFilmingPolicy.playerId")}
                      </FormLabel>
                      <p>{accountInfo?.data.player_number}</p>
                    </FormGroup>
                  </Col>
                </Row>
              )}

              <Row sm={1} lg={2}>
                {dateFields.map((date, index) => (
                  <Col key={index} className="mb-3 d-flex align-items-center">
                    <FormGroup className="flex-grow-1">
                      <FormLabel>
                        {t("pokerTableFilmingPolicy.selectDate")}
                      </FormLabel>
                      <InputGroup>
                        <InputGroup.Text>
                          <IoCalendar />
                        </InputGroup.Text>
                        <FormControl
                          disabled={!accountInfo}
                          type="date"
                          name={`dates[${index}]`}
                          value={date || ""}
                          onChange={(e) => handleDateChange(index, e)}
                          min={getTodayInEasternTime()} // Set the minimum date to today
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                ))}
              </Row>

              <Row className="g-3 align-items-center" sm={2} md={1} lg={2}>
                <Col>
                  <Button
                    className="mb-0"
                    disabled={!accountInfo}
                    onClick={addDateField}
                    fluid
                    label={
                      <>
                        <IoAddCircle style={{ marginRight: "8px" }} />
                        {t("pokerTableFilmingPolicy.addAnotherDate")}
                      </>
                    }
                  />
                </Col>
                {dateFields.length > 1 && (
                  <Col>
                    <Button
                      className="mb-0"
                      disabled={!accountInfo}
                      onClick={() => removeDateField(dateFields.length - 1)}
                      width="compact"
                      fluid
                      bgColor="var(--white)"
                      color="var(--red)"
                      hoverBgColor="var(--red)"
                      hoverColor="var(--white)"
                      label={
                        <>
                          <IoTrash style={{ marginRight: "8px" }} />
                          {t("pokerTableFilmingPolicy.removeDate")}
                        </>
                      }
                    />
                  </Col>
                )}
              </Row>

              <Row className="mt-5">
                {[
                  { platform: "facebook", icon: <IoLogoFacebook /> },
                  { platform: "instagram", icon: <IoLogoInstagram /> },
                  { platform: "youtube", icon: <IoLogoYoutube /> },
                  { platform: "twitch", icon: <IoLogoTwitch /> },
                  { platform: "x", icon: <IoLogoTwitter /> },
                  { platform: "tiktok", icon: <IoLogoTiktok /> },
                ].map(({ platform, icon }) => (
                  <Col md={6} key={platform}>
                    <FormGroup className="mb-3">
                      <FormLabel>
                        {t(`pokerTableFilmingPolicy.${platform}Handle`)}
                      </FormLabel>
                      <InputGroup>
                        <InputGroup.Text>{icon}</InputGroup.Text>
                        <FormControl
                          disabled={!accountInfo}
                          type="text"
                          name={`${platform}Handle`}
                          placeholder={t(
                            `pokerTableFilmingPolicy.${platform}Handle`
                          )}
                          value={formValues[`${platform}Handle`] || ""}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                ))}
              </Row>

              <FormGroup controlId="consent" className="mb-3">
                <Form.Check
                  disabled={!accountInfo}
                  type="checkbox"
                  name="consent"
                  label={t("pokerTableFilmingPolicy.consentText")}
                  checked={formValues.consent}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>

              {formSubmitted && (
                <Alert variant="success">
                    {t("pokerTableFilmingPolicy.formSuccessMessage")}
                </Alert>
              )}

              {errorMessage && (
                <Alert variant="danger">{errorMessage.substring(0, 100)}</Alert>
              )}

              <Button
                type="submit"
                disabled={isSubmitting || !accountInfo}
                bgColor="var(--red)"
                color="var(--white)"
                hoverColor="var(--black)"
                hoverBgColor="var(--white)"
                fluid
                label={t("pokerTableFilmingPolicy.submit")}
              />
            </StyledForm>
        </div>
      </Div>
    </>
  );
};

const Div = styled.div`
  height: auto;
  width: 100%;
  background: var(--black);
  & > div {
    height: auto;
    width: 100%;
    max-width: 1440px;
    color: var(--white);
    padding: 140px 0 0 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    & > div {
      padding: 80px 0 0 0;
    }
  }
`;

const ContentDiv = styled.div`
  margin: 20px 0;
  width: 100%;
  height: fit-content;
  background: var(--lightblack);
  padding: 20px;

  ol {
    padding-left: 20px;
    list-style: none;
    li {
      margin-bottom: 20px;

      h3 {
        font-weight: 600;
        border-bottom: 1px solid var(--black);
        margin-bottom: 10px;
      }

      ul {
        list-style: disc;
        padding-left: 20px;
      }
    }
  }
`;

const StyledForm = styled(GlobalStyledForm)`
  height: fit-content;
  max-width: 1200px;
  border-radius: 0;
  align-items: center;

  p {
    font-size: 1.4rem;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  & > button:last-child {
    max-width: 390px;
  }

  @media screen and (max-width: 768px) {
    & > div {
      flex-direction: column;
    }
  }
`;
export default PokerTableFilmingPolicy;
