import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
// import { useAccount } from "src/context/account/account.provider";

const SubTitleSection = ({ subTitle, text, as: Tag = 'h2', dark = true, className }) => {
  return (
    <Section dark={dark} className={`subtitle-section ${className || ''}`}>
      <Tag>{subTitle}</Tag>
      <ReactMarkdown>{text}</ReactMarkdown>
    </Section>
  );
};

SubTitleSection.propTypes = {
  subTitle: PropTypes.string.isRequired
};

const Section = styled.div`
  width: 100%;
  padding: 20px 0;
  h1, h2 {
    color: ${(props) => (props.dark ? "var(--lightgrey)" : "var(--black)")};
    margin: 0;
    font-weight: 700;
    font-size: 2rem;
    position: relative;
    &:before {
      border-bottom: 2px solid var(--lightblack);
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      max-width: 160px;
      border-bottom: 2px solid var(--red);
      position: relative;
    }
  }
  h3, h4 {
    color: ${(props) => (props.dark ? "var(--lightgrey)" : "var(--black)")};
    border-left: solid 3px var(--red);
    padding-left: 0.7rem;
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.3rem;
  }
  p {
    margin: 1rem 0;
    color: ${(props) => (props.dark ? "var(--lightgrey)" : "var(--black)")};
  }
  @media screen and (max-width: 991px) {
    h3 {
      font-size: 1.2rem;
    }
    h4 {
      font-size: 1.1rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
`;

export default SubTitleSection;
