import { useEffect, useRef } from "react";
import { useIsLgScreenUp } from "src/utils/windowUtils"; // Import the hook

const VideoComponent = () => {
  const videoRef = useRef(null);
  const isLgScreenUp = useIsLgScreenUp(); // Use the hook to determine if screen width is > 991

  useEffect(() => {
    const video = videoRef.current;
    let observer;

    const updateVideoSrc = () => {
      if (video) {
        video.src = isLgScreenUp
          ? "videos/PG-CommercialHD-Poker-focused.mp4"
          : "videos/PG-CommercialHD-Poker-focused-mobile4x3-30s.mp4";
        video.load();
      }
    };

    const handleIntersection = (entries) => {
      if (entries[0].isIntersecting) {
        updateVideoSrc(); // Load the appropriate video source based on screen size
        observer.disconnect(); // Stop observing once the video starts loading
      }
    };

    if (video) {
      observer = new IntersectionObserver(handleIntersection, {
        rootMargin: "0px",
        threshold: 0.25, // Start loading when 25% of the video is visible
      });

      observer.observe(video);
    }

    return () => {
      if (observer && video) {
        observer.unobserve(video);
      }
    };
  }, [isLgScreenUp]); // Re-run effect if screen size changes

  return (
    <video
      ref={videoRef}
      autoPlay
      loop
      muted
      playsInline
      preload="none" // Use "none" to lazy load the video
      onLoadedMetadata={(e) => {
        e.target.muted = true;
      }}
    >
      <source type="video/mp4" />
    </video>
  );
};

export default VideoComponent;
