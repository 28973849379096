//change DEV to PROD for prod api for user auth
const BASE_API = process.env.REACT_APP_BASE_URL;

export const email_verification = async (access_token, gid) => {
  try {
    const response = await fetch(`${BASE_API}/auth/email_verification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({ gid: gid }), // Send the code as part of the request body
    });
    if (response.ok) {
      return { success: true }; // Email confirmation code sent successfully
    } else {
      const errorData = await response.json();
      return {
        success: false,
        message: errorData.message || "Email confirmation code request failed",
      }; // Email confirmation code request failed
    }
  } catch (error) {
    console.error("Error requesting email confirmation code:", error.message);
    return {
      success: false,
      message: "Error occurred while requesting email confirmation code",
    }; // Error occurred while requesting email confirmation code
  }
};

export const send_verification_email = async (access_token) => {
  try {
    const response = await fetch(`${BASE_API}/auth/send_verification_email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (response.ok) {
      return { success: true }; // Email verification code sent successfully
    } else {
      const errorData = await response.json();
      return {
        success: false,
        message: errorData.message || "Email verification code request failed",
      }; // Email verification code request failed
    }
  } catch (error) {
    console.error("Error requesting email verification code:", error.message);
    return {
      success: false,
      message: "Error occurred while requesting email verification code",
    }; // Error occurred while requesting email verification code
  }
};

export const change_password = async (
  access_token,
  oldPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const response = await fetch(`${BASE_API}/auth/change_password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      body: JSON.stringify({
        old_password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
      }),
    });
    if (response.ok) {
      return { success: true }; // Password changed successfully
    } else {
      return { success: false, message: "Password change failed" }; // Password change failed
    }
  } catch (error) {
    console.error("Error changing password:", error.message);
    return {
      success: false,
      message: "Error occurred while changing password",
    }; // Error occurred while changing password
  }
};

export const forgot_password = async (email) => {
  try {
    const response = await fetch(`${BASE_API}/auth/forgot_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    if (response.ok) {
      return { success: true }; // Password reset request sent successfully
    } else {
      return { success: false, message: "Password reset request failed" }; // Password reset request failed
    }
  } catch (error) {
    console.error("Error resetting password:", error.message);
    return {
      success: false,
      message: "Error occurred while resetting password",
    }; // Error occurred while resetting password
  }
};

export const reset_password = async (payload) => {
  let validationMessages = [];
  const passwordValidationResult = validatePassword(payload.new_password);
  if (passwordValidationResult !== true) {
    // If the result is not `true`, it will be an array of failed validation messages.
    validationMessages = [...validationMessages, ...passwordValidationResult];
  }

  if (payload.new_password !== payload.new_password_confirmation) {
    validationMessages.push(
      "The new password does not match the confirmation password."
    );
  }

  if (validationMessages.length > 0) {
    return {
      success: false,
      messages: validationMessages, // Ensure that this key is consistent with what the client expects.
    };
  }

  const urlParams = new URLSearchParams(window.location.search);
  const gid = urlParams.get("gid");

  if (!gid) {
    console.error("GID (token) is missing from the URL.");
    return {
      success: false,
      message: ["Token is missing."], // Keeping the same structure as other error messages.
    };
  }

  try {
    const response = await fetch(`${BASE_API}/auth/reset_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        gid: gid,
        new_password: payload.new_password,
        new_password_confirmation: payload.new_password_confirmation,
      }),
    });

    if (response.ok) {
      sessionStorage.setItem("reset", "true")
      return { success: true }; // Password reset successful
    } else {
      const errorData = await response.json();
      console.error("Password reset error:", errorData);
      return {
        success: false,
        message: errorData.message || "Password reset failed", // Use the server-provided message if available
        details: errorData, // Include any additional details provided by the server.
      }; // Password reset failed with error details
    }
  } catch (error) {
    console.error("Error resetting password:", error);
    return {
      success: false,
      message: `Error occurred while resetting password: ${error.message}`, // Providing detailed error message from the exception.
    }; // Error occurred while resetting password
  }
};

export const validatePassword = (password) => {
  const validations = [
    {
      regex: /[A-Z]/,
      message: "Password must contain at least one uppercase letter.",
    },
    {
      regex: /[a-z]/,
      message: "Password must contain at least one lowercase letter.",
    },
    { regex: /\d/, message: "Password must contain at least one number." },
    // {
    //   regex: /[\W_]/,
    //   message: "Password must contain at least one special character.",
    // },
    {
      test: (p) => p.length >= 8,
      message: "Password must be at least 8 characters long.",
    },
  ];

  const failedValidations = validations.flatMap((v) =>
    typeof v.regex !== "undefined"
      ? !v.regex.test(password)
        ? [v.message]
        : []
      : !v.test(password)
      ? [v.message]
      : []
  );

  return failedValidations.length === 0 ? true : failedValidations;
};

export const sign_out = async () => {
  try {
    // Retrieve the access token from localStorage
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      // Handle the case when the access token is missing or invalid
      console.error("Access token is missing");
      return { success: false, message: "Access token is missing" };
    }
    const response = await fetch(`${BASE_API}/auth/sign_out`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${accessToken}`, // Include the user's access token
      },
    });
    if (response.ok) {
      // Clear the access token from storage (localStorage or sessionStorage)
      localStorage.removeItem("access_token");
      return { success: true };
    } else {
      // Handle errors, e.g., if the server returns a different status code
      console.error("Logout failed");
      return { success: false, message: "Logout failed" };
    }
  } catch (error) {
    console.error("Logout error:", error.message);
    return { success: false, message: "An error occurred while logging out" };
  }
};

export const sign_in = async (language, credentials) => {
  // Check if credentials are valid
  if (!credentials.username || !credentials.password) {
    return {
      success: false,
      message: "Please enter both username and password",
    };
  }
  try {
    // Set header for Accept-Language based on the language parameter
    const headers = {
      'Content-Type': 'application/json',
      'Accept-Language': language,
    };
    const response = await fetch(`${BASE_API}/auth/sign_in`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(credentials),
    });
    if (response.ok) {
      const { data } = await response.json();
      return data;
    } else if (response.status === 400) {
      return { success: false, message: "Invalid credentials" };
    } else {
      // Handle other server errors here
      return { success: false, message: "Server error" };
    }
  } catch (error) {
    console.error("Login error:", error.message);
    return { success: false, message: "An error occurred while logging in" };
  }
};
