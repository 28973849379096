import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { FaRegImage } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import {
  getSinglePastEventData,
} from "src/utils/tournamentUtils"; // Fetch all events data
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import { formatAmount } from "src/utils/formatters";

const PastEventWinners = () => {
  const [toggleImage, setToggleImage] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const location = useLocation();
  const imageRef = useRef();
  const { i18n, t } = useTranslation();

  // Fetch the current event data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      const event = await getSinglePastEventData(location, i18n); // Fetch single event data
      setCurrentEvent(event);
    };
    fetchData();
  }, [i18n]);

  const handleSelectedImage = (item) => {
    setToggleImage(true);
    setSelectedItemIndex(item);
  };

  if (toggleImage) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "initial";
  }

  return (
    <Div>
      {currentEvent && (
        <>
          <div>
            <ResponsiveImage
              src={currentEvent?.image}
              alt={currentEvent?.title}
            />
            <ResponsiveImage
              src={currentEvent?.image_mobile}
              alt={currentEvent?.url}
            />
          </div>
          <div>
            {currentEvent?.winnerDetail?.name && (
              <>
                <h1>{t("tournament.winners.winnerDetails")}</h1>
                <span></span>
                <FirstMain key={currentEvent.title}>
                  <aside>
                    <ul>
                      <li itemScope itemType="http://schema.org/Person">
                        <span>{t("tournament.winners.winner")}</span>
                        <h2 itemProp="name">
                          {currentEvent.winnerDetail.name}
                        </h2>
                      </li>
                      <li>
                        <span>{t("tournament.winners.origin")}</span>
                        {currentEvent.winnerDetail.origin}
                        <ResponsiveImage
                          src={`flags/${
                            currentEvent.winnerDetail.country === "Canada"
                              ? "canada_flag.png"
                              : "american_flag.png"
                          }`}
                          alt={currentEvent.winnerDetail.country}
                        />
                      </li>
                      <li>
                        <span>{t("tournament.winners.event")}</span>
                        {currentEvent.winnerDetail.event}
                      </li>
                      {currentEvent.winnerDetail.prize && (
                        <li>
                          <span>{t("tournament.winners.prize")}</span>
                          {formatAmount(
                            currentEvent.winnerDetail.prize,
                            i18n.language
                          )}
                        </li>
                      )}
                    </ul>
                  </aside>
                  <div>
                    <ResponsiveImage
                      src={currentEvent.winnerDetail.image}
                      alt={currentEvent.winnerDetail.name}
                    />
                  </div>
                </FirstMain>
              </>
            )}
            {currentEvent?.table?.length > 0 && (
              <TableDiv>
                <table>
                  <thead>
                    <tr>
                      <th>{t("tournament.winners.event")}</th>
                      {currentEvent.table[0].tournament && (
                        <th>{t("tournament.winners.tournament")}</th>
                      )}
                      <th>{t("tournament.winners.winner")}</th>
                      <th>Image</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEvent.table.map((el, ind) => (
                      <tr key={el.image}>
                        <td>{el.event || `#${ind + 1}`}</td>
                        {el.tournament && <td>{el.tournament}</td>}
                        <td>{el.winner}</td>
                        <td
                          onClick={() =>
                            el.image !== "" && handleSelectedImage(ind)
                          }
                        >
                          {el.image !== "" && (
                            <>
                              <FaRegImage /> {t("tournament.winners.winner")}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableDiv>
            )}
            {toggleImage && (
              <PopUpImageDiv>
                <div ref={imageRef}>
                  <ResponsiveImage
                    src={currentEvent?.table[selectedItemIndex]?.image}
                    alt="selected-winner-img"
                  />
                  <div>
                    <span>
                      {t("tournament.winners.event")}{" "}
                      {currentEvent.table[selectedItemIndex]?.event ||
                        `#${selectedItemIndex + 1}`}{" "}
                      {currentEvent.table[selectedItemIndex]?.tournament}{" "}
                      {t("tournament.winners.winner")}{" "}
                      {currentEvent.table[selectedItemIndex]?.winner}
                    </span>
                  </div>
                  <div>
                    <IoClose onClick={() => setToggleImage(false)} />
                  </div>
                </div>
              </PopUpImageDiv>
            )}
          </div>
        </>
      )}
    </Div>
  );
};

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const Div = styled.div`
  height: auto;
  width: 100%;
  background: var(--black);
  position: relative;
  z-index: 1;
  & > div:first-child {
    height: fit-content;
    width: 100%;
    &::after {
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      content: " ";
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }
    & > img {
      width: 100%;
      height: fit-content;
      object-fit: cover;
      object-position: center;
    }
    & > img:last-child {
      display: none;
    }
  }
  & > div:nth-child(2) {
    padding-top: 20px;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    & > h1:first-child {
      text-align: center;
      color: var(--white);
      font-weight: 900;
      margin-bottom: 10px;
      font-size: 45px;
    }
    & > span:nth-child(2) {
      margin: 0 auto;
      width: 100%;
      max-width: 275px;
      border-bottom: 2px solid var(--red);
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:first-child {
      padding: 80px 0px 0px;
      & > img:first-child {
        display: none;
      }
      & > img:last-child {
        display: block;
      }
    }
    & > div:nth-child(2) {
      padding: 0;
      & > h1:first-child {
        font-size: 30px;
      }
    }
  }
`;
const FirstMain = styled.div`
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  margin: 50px auto;
  background: var(--lightblack);
  display: flex;
  flex-flow: row nowrap;
  color: var(--white);
  & > aside {
    flex-basis: 50%;
    padding: 0 35px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    & > ul {
      padding: 0;
      list-style: none;
      & > li {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        font-size: 22px;
        border-left: 4px solid var(--red);
        margin-bottom: 20px;
        & > span {
          font-weight: 700;
          margin-left: 5px;
        }
        & > h2 {
          margin: 0;
          font-size: 22px;
        }
        & > img {
          max-width: 22px;
          height: auto;
          object-fit: contain;
        }
      }
    }
  }
  & > div {
    flex-basis: 50%;
    & > img {
      object-fit: contain;
      height: auto;
      width: calc(100% - 35px);
      position: relative;
      top: 35px;
    }
    & > video {
      object-fit: contain;
      height: auto;
      width: calc(100% - 35px);
      position: relative;
      top: 35px;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
    margin: 20px auto;
    padding: 0 20px;
    & > aside {
      width: 100%;
      height: 100%;
      padding: 0;
      flex-basis: 100%;
      display: block;
      & > ul {
        & > li {
          font-size: 18px;
          margin-bottom: 10px;
          gap: 10px;
          & > h2 {
            font-size: 18px;
          }
        }
      }
    }
    & > div {
      & > img {
        display: block;
        position: unset;
        width: 100%;
      }
    }
  }
`;
const TableDiv = styled.div`
  margin: 40px auto 20px auto;
  border: 1px solid var(--lightblack);
  width: 100%;
  max-width: 1440px;
  height: fit-content;
  & > table {
    width: 100%;
    height: fit-content;
    border-collapse: collapse;
    & > thead {
      & > tr {
        & > th {
          background: var(--red);
          color: var(--white);
          font-size: 16px;
          border-bottom: none;
          padding: 8px;
          line-height: 1.42857143;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }
    & > tbody {
      & > tr {
        border-top: 1px solid var(--lightblack);
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        & > td {
          border-right: 1px solid var(--lightblack);
          border-top: none;
          text-align: center;
          color: var(--white);
          font-size: 15px;
          padding: 8px;
          line-height: 1.42857143;
          vertical-align: middle;
          & > svg {
            vertical-align: middle;
          }
          & > img {
            max-width: 22px;
            height: auto;
            object-fit: contain;
          }
        }
        & > td:last-child {
          cursor: pointer;
          &:hover {
            color: var(--red);
            border-bottom: 1px solid var(--red);
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > table {
      & > thead {
        & > tr {
          & > th {
            font-size: 14px;
          }
        }
      }
      & > tbody {
        & > tr {
          & > td {
            font-size: 12px;
            font-weight: 300;
          }
        }
      }
    }
  }
`;
const PopUpImageDiv = styled.div`
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  & > div {
    animation: ${fadeIn} 0.3s linear;
    position: relative;
    padding: 15px;
    width: fit-content;
    height: auto;
    background: var(--white);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    color: var(--lightblack);
    & > img:first-child {
      vertical-align: middle;
      width: 100%;
      max-height: 650px;
      object-fit: cover;
      object-position: center;
      overflow: hidden;
    }
    & > div:nth-child(2) {
      width: 100%;
      margin: 0;
      text-align: center;
      background: var(--lightblack);
      padding: 11px;
      & > span {
        width: 100%;
        text-transform: uppercase;
        font-size: 12px;
        box-sizing: border-box;
        line-height: 17px;
        white-space: pre-wrap;
        text-shadow: 0 1px 2px var(--lightblack);
        color: var(--white);
        font-weight: bold;
      }
    }
    & > div:last-child {
      position: absolute;
      right: -15px;
      top: -15px;
      color: var(--white);
      background: var(--red);
      padding: 10px;
      z-index: 2;
      border-radius: 50%;
      cursor: pointer;
      & > svg {
        color: white;
        font-size: 30px;
        vertical-align: middle;
      }
      &:hover {
        background-color: var(--white);
        & > svg {
          color: var(--black);
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 0 20px;
  }
`;

export default PastEventWinners;
