import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import { sign_in } from "../../api/auth";
import { useTranslation } from "react-i18next";
import { useAccount } from "src/context/account/account.provider";
import { StyledForm } from "../../GlobalStyle";
import {
  FormGroup,
  FormControl,
  FormLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import Button from "src/components/Button/Button";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import AuthServiceInfo from "src/components/AuthServiceInfo";
import TopLogo from "src/components/Auth/TopLogo";
import NewMemberSection from "src/components/Auth/NewMemberSection";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Login = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useAccount();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.target);
    const credentials = {
      username: formData.get("username"),
      password: formData.get("password"),
    };

    try {
      const result = await sign_in(i18n.language, credentials);
      if (result.access_token) {
        login(result.access_token);

        const referrer = sessionStorage.getItem("referrer");
        if (referrer) {
          sessionStorage.removeItem("referrer");
          navigate(referrer, { replace: true });
        // } else if (sessionStorage.getItem("reset") === "true") {
        //   sessionStorage.removeItem("reset");
        //   navigate(t("routes.homepage"), { replace: true });
        } else {
          navigate(t("routes.homepage"), { replace: true });
        }
      } else {
        setErrorMessage(
          i18n.language === "en"
            ? "Authentication failed. Please check your username and password, and try again."
            : "L'authentification a échoué. Veuillez vérifier votre nom d'utilisateur et votre mot de passe, puis réessayez."
        );
        console.error("Authentication error:", result.message);
      }
    } catch (error) {
      console.error("Sign in failed:", error);
      setErrorMessage(
        i18n.language === "en"
          ? "An error occurred during sign in."
          : "Une erreur est survenue lors de la connexion."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <auth-page>
      <TopLogo />
      <Row xs={1} md={2}>
        <Col className="px-2">
          <StyledForm onSubmit={handleSubmit} className="login-form">
            <SubTitleSection subTitle={t("auth.login.title")} as={'h1'} text={t("auth.login.description")} className="py-0" />
            <FormGroup>
              <div className="label-link-container">
                <FormLabel className="form-label-check">{t("auth.form.userName.label")}</FormLabel>
                <Link to={t("routes.signUpExistingUser")} className="form-link">
                  {t("auth.login.forgotUsername")}
                </Link>
              </div>
              <FormControl type="text" id="username" name="username" placeholder={t("auth.form.userName.placeholder")} required />
            </FormGroup>
            <FormGroup>
              <div className="label-link-container">
                <FormLabel className="form-label-check">{t("auth.form.password.label")}</FormLabel>
                <Link to={t("routes.forgotPassword")} className="form-link">
                  {t("auth.login.forgotPassword")}
                </Link>
              </div>
              <FormControl
                type="password"
                id="password"
                name="password"
                placeholder={t("auth.form.password.placeholder")}
                required
              />
            </FormGroup>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Button
              type="submit"
              disabled={isLoading}
              bgColor="var(--red)"
              color="var(--white)"
              hoverColor="var(--black)"
              hoverBgColor="var(--white)"
              fluid
              label={
                isLoading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  t("auth.login.loginButton")
                )
              }
            />
            <NewMemberSection />
          </StyledForm>
        </Col>
        <Col className="d-flex align-items-center justify-content-center">
          <AuthServiceInfo />
        </Col>
      </Row>
    </auth-page>
  );
};


export default Login;
